import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "automatic-builds"
    }}>{`Automatic Builds`}</h1>
    <p>{`Nano API supports automatic builds for your services. This means that whenever you push a new commit to your repository, Nano API will automatically build and deploy your service, tagged with that commit's hash.`}</p>
    <p>{`To set this up, see the following steps, which assume you have already completed the `}<a parentName="p" {...{
        "href": "https://docs.nanoapi.io/getting-started"
      }}>{`Getting Started`}</a>{` guide:`}</p>
    <h2 {...{
      "id": "step-1-create-an-api-token"
    }}>{`Step 1: Create an API Token`}</h2>
    <p>{`First, you will need to create an API token. This token will be used to authenticate your service with Nano API. To create an API token, go to the `}<a parentName="p" {...{
        "href": "https://app.nanoapi.io/tokens"
      }}>{`API Tokens`}</a>{` page and click on the "Create  a new token" button.`}</p>
    <p>{`Add a name for your token, and it will appear in the upper right of the screen. Click on the token to copy it to your clipboard.`}</p>
    <p>{`You can create as many tokens as you need, and you can revoke them at any time.`}</p>
    <p><img alt="Create API Token" src={require("../../public/token.png")} /></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Keep this token safe. It is used to authenticate your service with Nano API.`}</p>
    </blockquote>
    <h2 {...{
      "id": "step-2-add-the-api-token-to-your-repository-as-a-secret-value"
    }}>{`Step 2: Add the API Token to Your Repository as a Secret Value`}</h2>
    <p>{`Next, you will need to add the API token to your repository as a secret value. This is done to ensure that your token is not exposed in your repository.`}</p>
    <p>{`To add the API token as a secret value, go to your repository on GitHub or GitLab and navigate to the "Settings" page. From there, click on the "Secrets" or "Environment Variables" tab, depending on your platform, and create a new secret named `}<inlineCode parentName="p">{`NANO_API_KEY`}</inlineCode>{`. Paste the API token as the value for this secret.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You can, of course, use whatever name you prefer for the secret, but you will need to reference it in your CI setup in the next step correctly.`}</p>
    </blockquote>
    <p><img alt="Add Secret Value" src={require("../../public/secrets.png")} /></p>
    <h2 {...{
      "id": "step-3-set-up-your-cicd-pipeline"
    }}>{`Step 3: Set Up Your CI/CD Pipeline`}</h2>
    <p>{`Finally, you will need to set up your CI/CD pipeline to trigger a build and deploy your service whenever you push a new commit to your repository. Using GitHub Actions as an example, you can create a workflow file in your repository under `}<inlineCode parentName="p">{`.github/workflows`}</inlineCode>{` with the following content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# .github/workflows/deploy_using_nano.yml

name: nanoapi_build_and_deploy

on: [push]
jobs:
  ci:
    runs-on: ubuntu-latest
    steps:
      # Request build and stream output
      - name: Build Serverless functions and deploy via Nano API
        uses: Nano-API/Deploy@main
        with:
          token: \${{ secrets.GITHUB_TOKEN }}
          # Best practice is to store your API key as a secret
          api_key: \${{ secrets.NANO_API_KEY }}
`}</code></pre>
    <p>{`This workflow file will trigger a build and deploy action whenever you push a new commit to your repository. You can customize the workflow file to suit your needs, such as specifying the branch to deploy from or adding additional steps.`}</p>
    <h2 {...{
      "id": "step-4-push-a-new-commit"
    }}>{`Step 4: Push a New Commit`}</h2>
    <p>{`With your CI/CD pipeline set up, you can now push a new commit to your repository. This will trigger the workflow and automatically build and deploy your service using Nano API. You will be able to watch the output in the Actions tab of your repository.`}</p>
    <h1 {...{
      "id": "wrap-up"
    }}>{`Wrap Up`}</h1>
    <p>{`And that's it! You now have automatic builds set up for your service with Nano API. Whenever you push a new commit, Nano API will automatically build and deploy your service, making it easy to iterate and deploy changes quickly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      